<template>
  <fragment>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <v-text-field
        v-model="option.text"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.optionTitle')"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <v-text-field
        v-model="option.value"
        :disabled="$store.state.app.onlyShow"
        label="url"
        outlined
        dense
        hide-details
        placeholder="http://url.com"
      ></v-text-field>
    </v-col>
    <v-col
      v-if="!$store.state.app.onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteOptionsMessaje(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    option: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteOptionsMessaje']),
  },
}
</script>
