<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('permiso.chatbot')} / ${$t('permiso.messagesInit')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'chatbot-messages-auto' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="model.order"
                      :disabled="onlyShow"
                      :label="$t('lbl.order')"
                      outlined
                      dense
                      hide-details
                      type="number"
                      min="1"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-switch
                      v-model="model.active"
                      :label="$t('lbl.active')"
                      :disabled="onlyShow"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-textarea
                      v-model="model.text"
                      :disabled="onlyShow"
                      :label="$t('lbl.message')"
                      outlined
                      no-resize
                      rows="3"
                      row-height="15"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        class="pb-2 pt-2"
                      >
                        <b>{{ $t('lbl.options') }}</b>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                      >
                        <v-tooltip
                          v-if="!$store.state.app.onlyShow"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              @click="adicionarOptionsMessaje()"
                            >
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('btn.insert') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="pb-5"
                      >
                        <v-row
                          class="pt-5 pb-5"
                        >
                          <OptionsMessaje
                            v-for="(option, i) in $store.state.app.optionsChatBotMessages"
                            :key="i"
                            :option="option"
                            :pos="i"
                          />
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import OptionsMessaje from '../utils/OptionsMessage.vue'

export default {
  components: {
    AppCardCode,
    OptionsMessaje,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      model: {},
      showOptions: false,
      loading: false,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('chat-message-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      optionsChatBotMessages: state => state.app.optionsChatBotMessages,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.getItem()
    if (sessionStorage.getItem('chat-message-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
  },
  methods: {
    ...mapMutations(['adicionarOptionsMessaje', 'deleteOptionsMessaje', 'updateOptionsMessaje', 'setOnlyShow']),

    getItem() {
      if (sessionStorage.getItem('chat-message-id') !== null) {
        const id = sessionStorage.getItem('chat-message-id')
        this.axios
          .get(`chatbot/messages/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            if (this.model.options !== null && this.model.options !== 'null') {
              this.updateOptionsMessaje(this.model.options)
            } else {
              this.updateOptionsMessaje([])
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
        this.updateOptionsMessaje([])
      }
    },

    save() {
      if (this.model.order && this.model.text) {
        this.loading = true
        const json = {
          order: this.model.order,
          text: this.model.text,
          active: this.model.active,
          options: this.$store.state.app.optionsChatBotMessages,
        }

        if (sessionStorage.getItem('chat-message-id') === null) {
          this.axios
            .post('chatbot/messages', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                }
              } else {
                sessionStorage.removeItem('chat-message-id')
                this.$router.push({ name: 'chatbot-messages-auto' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`chatbot/messages/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                sessionStorage.removeItem('chat-message-id')
                this.$router.push({ name: 'chatbot-messages-auto' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
